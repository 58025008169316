<template>
  <div class="v-return">
    <div class="c-return-form form">
      <b-form @submit.stop.prevent="onSubmit">
        <div class="c-return-form__inner">
          <p>{{ $t('* indicates a required field') }}</p>

          <b-form-fieldset
            class="c-return-form__customer-references form-wrapper"
            :label="$t('CustomerReferences')"
          >
            <CustomerReferences
              v-model="customerReferencesData"
            />
          </b-form-fieldset>

          <b-form-fieldset
            class="c-return-form__complaint-description form-wrapper"
            :label="$t('ComplaintDescription')"
          >
            <ComplaintDescription
              v-model="complaintDescriptionData"
            />
          </b-form-fieldset>

            <b-form-fieldset
              class="c-return-form__complaint-samples form-wrapper"
              :label="$t('ComplaintSamples')"
            >
              <ComplaintSamples
                v-model="complaintSamplesData"
              />
            </b-form-fieldset>

          <b-form-group>
            <b-form-checkbox
              id="terms-and-conditions"
              v-model="acceptTerms"
              name="acceptTerms"
              v-validate="{ required: true }"
              value="1"
              unchecked-value="0"
              required
              :state="validateState('acceptTerms')"
            >
              <span v-html="$t('I have read the privacy statement') +'*'"></span>
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="c-return-form__actions">
          <b-button type="submit" variant="primary">{{ $t('Submit') }}</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import ValidateState from '@/mixin/validateState';
import CustomerReferences from '@/components/CustomerReferences.vue';
import ComplaintDescription from '@/components/ComplaintDescription.vue';
import ComplaintSamples from '@/components/ComplaintSamples.vue';
import MailService from '../services/mailService';
import Firebase from '../services/firebase';

export default {
  name: 'return',
  mixins: [
    ValidateState,
  ],
  components: {
    ComplaintSamples,
    CustomerReferences,
    ComplaintDescription,
  },
  data() {
    return {
      acceptTerms: false,
      errors: false,
    };
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  methods: {
    async validate() {
      const validators = [];
      validators.push(this.$validator.validateAll());

      this.getChildren(this.$children).forEach((child) => {
        validators.push(child.$validator.validateAll());
      });

      return Promise.all(validators);
    },
    generateFileName(fileName) {
      return `${this.customerReferencesData.organisation}-${Date.now()}/${fileName}`;
    },
    getChildren(children) {
      let subChildren = [];

      subChildren = subChildren.concat(children);

      _.forEach(children, (child) => {
        if (child.$children.length) {
          subChildren = subChildren.concat(this.getChildren(child.$children));
        }
      });

      return subChildren;
    },
    async onSubmit() {
      // Validate.
      const result = await this.validate();
      if (result.includes(false)) {
        return;
      }

      const data = {
        customerReferencesData: _.cloneDeep(this.customerReferencesData),
        complaintDescriptionData: _.cloneDeep(this.complaintDescriptionData),
        complaintSamplesData: _.cloneDeep(this.complaintSamplesData),
        mail: _.cloneDeep(this.mail),
      };

      // Save files in firebase storage.
      if (this.complaintDescriptionData.file && this.complaintDescriptionData.file.length > 0) {
        const fileUrlPromises = this.complaintDescriptionData.file.map(async (uploadedFile) => {
          const complaintDescriptionFile = await Firebase.storeFile(
            this.generateFileName(uploadedFile.name),
            uploadedFile,
          );
          return complaintDescriptionFile.ref.getDownloadURL();
        });

        const fileUrls = await Promise.all(fileUrlPromises);

        data.complaintDescriptionData.file = fileUrls.join(', ');
        this.$store.commit('updateComplaintDescriptionDataFile', fileUrls);
      }

      // Store data in firebase database.
      await Firebase.addSubmission(data);

      // Send mail.
      await MailService.sendSubmissionMail(data);

      // Redirect to confirmation page.
      this.$router.push({ name: 'confirmation' });

      // Clean data
      await this.$store.dispatch('cleanData');
    },
    updateSubmission(value, key) {
      this.$store.commit('updateSubmission', { [key]: value });
    },
  },
  computed: {
    customerReferencesData: {
      get() {
        return this.$store.state.submission.customerReferencesData;
      },
      set(value) {
        this.updateSubmission(value, 'customerReferencesData');
      },
    },
    complaintSamplesData: {
      get() {
        return this.$store.state.submission.complaintSamplesData;
      },
      set(value) {
        this.updateSubmission(value, 'complaintSamplesData');
      },
    },
    complaintDescriptionData: {
      get() {
        return this.$store.state.submission.complaintDescriptionData;
      },
      set(value) {
        this.updateSubmission(value, 'complaintDescriptionData');
      },
    },
    mail: {
      get() {
        return this.$store.state.submission.mail;
      },
    },
  },
};
</script>

<style lang="scss">
  .v-return {
    min-height: 500px;
    padding: 4rem;
  }

  .c-return-form {
    &__inner {
      padding: 3rem 4rem;
      background: white;
    }

    &__actions {
      margin-top: 2rem;
      text-align: right;
    }

      .l-wrapper {
        @include media-breakpoint-up(sm) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .form-group-without-select {
            width: 49%;
            margin-bottom: 1rem;
          }

          .l-col {
            width: 49%;
            margin-bottom: 1rem;

            &.full-width {
              width: 100%;
            }
          }
        }
      }
  }
</style>
