<template>
  <div class="l-wrapper">
    <b-form-group class="form-group-without-select">
      <b-form-input
        name="customerNumber"
        :value="customerReferencesData.customerNumber"
        @input="update('customerNumber', $event)"
        :placeholder="$t('customerNumber')"
        :state="validateState('customerNumber')"
      ></b-form-input>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="organisation"
        :value="customerReferencesData.organisation"
        @input="update('organisation', $event)"
        :placeholder="$t('organisation') + '*'"
        v-validate="{ required: true }"
        :state="validateState('organisation')"
        aria-describedby="organisation-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="organisation-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="zipcode"
        :value="customerReferencesData.zipcode"
        @input="update('zipcode', $event)"
        :placeholder="$t('zipcode') + '*'"
        v-validate="{ required: true }"
        :state="validateState('zipcode')"
        aria-describedby="zipcode-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="zipcode-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="street"
        :value="customerReferencesData.street"
        @input="update('street', $event)"
        :placeholder="$t('street') + '*'"
        v-validate="{ required: true }"
        :state="validateState('street')"
        aria-describedby="street-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="street-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="city"
        :value="customerReferencesData.city"
        @input="update('city', $event)"
        :placeholder="$t('city') + '*'"
        v-validate="{ required: true }"
        :state="validateState('city')"
        aria-describedby="city-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="city-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="customerComplaintRef"
        :value="customerReferencesData.customerComplaintRef"
        @input="update('customerComplaintRef', $event)"
        :placeholder="$t('customerComplaintRef')"
        :state="validateState('customerComplaintRef')"
      ></b-form-input>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="contactPerson"
        :value="customerReferencesData.contactPerson"
        @input="update('contactPerson', $event)"
        :placeholder="$t('contactPerson') + '*'"
        v-validate="{ required: true }"
        :state="validateState('contactPerson')"
        aria-describedby="contactPerson-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="contactPerson-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="telNrContactPerson"
        :value="customerReferencesData.telNrContactPerson"
        @input="update('telNrContactPerson', $event)"
        :placeholder="$t('telNrContactPerson') + '*'"
        v-validate="{ required: true }"
        :state="validateState('telNrContactPerson')"
        aria-describedby="telNrContactPerson-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="telNrContactPerson-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="emailContactPerson"
        :value="customerReferencesData.emailContactPerson"
        @input="update('emailContactPerson', $event)"
        :placeholder="$t('emailContactPerson') + '*'"
        type="email"
        v-validate="{ email: true, required: true }"
        :state="validateState('emailContactPerson')"
        aria-describedby="emailContactPerson-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="emailContactPerson-live-feedback">
        {{ $t('This is an invalid e-mail') }}
      </b-form-invalid-feedback>
      </b-form-group>
  </div>
</template>

<script lang="js">
import ValidateState from '@/mixin/validateState';

export default {
  name: 'CustomerReferences',
  mixins: [
    ValidateState,
  ],
  model: {
    prop: 'customerReferencesData',
  },
  props: {
    customerReferencesData: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.customerReferencesData, [key]: value });
    },
  },
  inject: ['$validator'],
};
</script>

<style lang="scss">

</style>
