<template>
  <div class="l-wrapper">
    <div class="l-col">
      <b-form-group :label="$t('available')" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="available.selected"
          :options="available.options"
          @input="update('available', $event)"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <div class="l-col" v-if="available.selected === 'yes'">
        <b-form-group>
          <b-form-input
            name="amount"
            :value="complaintSamplesData.amount"
            @input="update('amount', $event)"
            :placeholder="$t('amount')"
            :state="validateState('amount')"
            type="number"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group :label="$t('condition')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="condition.selected"
        :options="condition.options"
        @input="update('condition', $event)"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <div class="l-col" >
      <b-form-group :label="$t('infectious')" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="infectious.selected"
          :options="infectious.options"
          @input="update('infectious', $event)"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <p v-if="infectious.selected === 'yes'">{{ $t('attest') }}</p>
    </div>

    <b-form-group :label="$t('cytostatics')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="cytostatics.selected"
        :options="cytostatics.options"
        @input="update('cytostatics', $event)"
        :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <div class="full-width l-col">
        <b-form-group>
          <b-form-textarea
            name="remarks"
            id="textarea"
            :value="complaintSamplesData.remarks"
            @input="update('remarks', $event)"
            :placeholder="$t('remarks') + '*'"
            :state="validateState('remarks')"
            rows="10"
            max-rows="10"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
</template>

<script lang="js">

import ValidateState from '@/mixin/validateState';
import i18n from '../i18n';

export default {
  name: 'ComplaintSamples',
  data: () => ({
    available: {
      selected: 'no',
      options: [
        { text: i18n.tc('No'), value: 'no' },
        { text: i18n.tc('Yes'), value: 'yes' },
      ],
    },
    condition: {
      selected: 'unused',
      options: [
        { text: i18n.tc('unused'), value: 'unused' },
        { text: i18n.tc('used'), value: 'used' },
      ],
    },
    infectious: {
      selected: 'no',
      options: [
        { text: i18n.tc('no'), value: 'no' },
        { text: i18n.tc('yes'), value: 'yes' },
      ],
    },
    cytostatics: {
      selected: 'no',
      options: [
        { text: i18n.tc('no'), value: 'no' },
        { text: i18n.tc('yes'), value: 'yes' },
      ],
    },
  }),
  inject: ['$validator'],
  mixins: [
    ValidateState,
  ],
  model: {
    prop: 'complaintSamplesData',
  },
  props: {
    complaintSamplesData: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.complaintSamplesData, [key]: value });
    },
  },
};
</script>

<style lang="scss">

</style>
