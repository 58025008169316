import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    submission: {
      acceptTerms: false,
      customerReferencesData: {
        customerNumber: '',
        organisation: '',
        zipcode: '',
        street: '',
        city: '',
        customerComplaintRef: '',
        contactPerson: '',
        telNrContactPerson: '',
        emailContactPerson: '',
      },
      complaintDescriptionData: {
        product: '',
        article: '',
        batchNumber: '',
        dateOfComplaintObservation: '',
        descriptionOfComplaint: '',
        timingOfComplaintObservation: null,
        text: '',
        numberOfUnitsThatPreservedTheSameDefect: '',
        attachments: 'no',
        file: null,
        adr: 'no',
      },
      complaintSamplesData:
      {
        available: 'no',
        amount: '',
        condition: 'no',
        infectious: 'no',
        cytostatics: 'no',
      },
      mail: 'Bene.Complaints@fresenius-kabi.com',
    },
  },
  mutations: {
    updateSubmission(state, submission) {
      Object.assign(state.submission, submission);
    },
    cleanData(state, payload) {
      _.forEach(state.submission.complaintDescriptionData, (item: any, key: any) => {
        // @ts-ignore
        state.submission.complaintDescriptionData[key] = null;
      });
    },
    updateComplaintDescriptionDataFile(state, fileUrls) {
      state.submission.complaintDescriptionData.file = fileUrls;
    },
    updatemail(state, value) {
      state.submission.mail = value;
    },
  },
  actions: {
    cleanData(context) {
      context.commit('cleanData');
    },
  },
});
