<template>
  <div class="l-wrapper">

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="product"
        :value="complaintDescriptionData.product"
        @input="update('product', $event)"
        :placeholder="$t('product') + '*'"
        v-validate="{ required: true }"
        :state="validateState('product')"
        aria-describedby="product-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="product-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="article"
        :value="complaintDescriptionData.article"
        @input="update('article', $event)"
        :placeholder="$t('article') + '*'"
        v-validate="{ required: true }"
        :state="validateState('article')"
        aria-describedby="article-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="article-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="batchNumber"
        :value="complaintDescriptionData.batchNumber"
        @input="update('batchNumber', $event)"
        :placeholder="$t('batchNumber') + '*'"
        v-validate="{ required: true }"
        :state="validateState('batchNumber')"
      ></b-form-input>
      <b-form-invalid-feedback id="batchNumber-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group class="form-group-without-select">
      <b-form-datepicker
        name="dateOfComplaintObservation"
        :value="complaintDescriptionData.dateOfComplaintObservation"
        @input="update('dateOfComplaintObservation', $event)"
        :placeholder="$t('dateOfComplaintObservation')"
        :state="validateState('dateOfComplaintObservation')"
      ></b-form-datepicker>
    </b-form-group>

    <div class="full-width l-col">
      <b-form-group>
        <b-form-textarea
          name="descriptionOfComplaint"
          id="textarea"
          :value="complaintDescriptionData.descriptionOfComplaint"
          @input="update('descriptionOfComplaint', $event)"
          :placeholder="$t('descriptionOfComplaint') + '*'"
          v-validate="{ required: true }"
          :state="validateState('descriptionOfComplaint')"
          rows="10"
          max-rows="10"
          aria-describedby="descriptionOfComplaint-live-feedback"
        ></b-form-textarea>
        <b-form-invalid-feedback id="descriptionOfComplaint-live-feedback">
          {{ $t('This is a required field') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <div class="l-col">
      <div class="mod-select-box">
        <b-form-group>
          <b-form-select
            name="timingOfComplaintObservation"
            :value="complaintDescriptionData.timingOfComplaintObservation"
            @input="update('timingOfComplaintObservation', $event)"
            :options="reasonOptions"
            v-validate="{ required: true }"
            :state="validateState('timingOfComplaintObservation')"
            aria-describedby="timingOfComplaintObservation-live-feedback"
          ></b-form-select>
          <b-form-invalid-feedback id="timingOfComplaintObservation-live-feedback">
            {{ $t('This is a required field') }}
        </b-form-invalid-feedback>
      </b-form-group>
        <div class="select-button"><i class="icon"><ArrowIcon /></i></div>
      </div>
    </div>

    <b-form-group class="form-group-without-select">
      <b-form-input
        name="numberOfUnitsThatPreservedTheSameDefect"
        :value="complaintDescriptionData.numberOfUnitsThatPreservedTheSameDefect"
        @input="update('numberOfUnitsThatPreservedTheSameDefect', $event)"
        :placeholder="$t('numberOfUnitsThatPreservedTheSameDefect') + '*'"
        v-validate="{ required: true }"
        :state="validateState('numberOfUnitsThatPreservedTheSameDefect')"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="numberOfUnitsThatPreservedTheSameDefect-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="full-width l-col">
      <b-form-group>
        <b-form-textarea
          name="text"
          id="textarea"
          :value="complaintDescriptionData.text"
          @input="update('text', $event)"
          :placeholder="$t('details')"
          rows="6"
          max-rows="6"
          v-show="showRemarks"
        ></b-form-textarea>
        <b-form-invalid-feedback id="text-live-feedback">
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <b-form-group :label="$t('attachments')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="attachments.selected"
        :options="attachments.options"
        @input="update('attachments', $event)"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <div class="l-col" v-if="attachments.selected === 'yes'">
      <b-form-group>
        <b-form-file
          :value="complaintDescriptionData.file"
          @input="update('file', $event)"
          :placeholder="$t('Choose a file...')"
          drop-placeholder="Drop file here..."
          multiple
        ></b-form-file>
      </b-form-group>
    </div>

    <b-form-group :label="$t('adr')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="adr.selected"
        :options="adr.options"
        @input="update('adr', $event)"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

  </div>
</template>

<script lang="js">

import ValidateState from '@/mixin/validateState';
import ArrowIcon from '../assets/icon-arrow.svg';
import i18n from '../i18n';

export default {
  name: 'ComplaintDescription',
  data: () => ({
    reasonOptions: [
      { value: null, text: `${i18n.tc('timingOfComplaintObservation')}*` },
      { value: 'before-unpacking', text: i18n.tc('before-unpacking') },
      { value: 'after-unpacking-but-before-usage', text: i18n.tc('after-unpacking-but-before-usage') },
      { value: 'during-usage', text: i18n.tc('during-usage') },
    ],
    attachments: {
      selected: 'no',
      options: [
        { text: i18n.tc('No'), value: 'no' },
        { text: i18n.tc('Yes'), value: 'yes' },
      ],
    },
    adr: {
      selected: 'no',
      options: [
        { text: i18n.tc('No'), value: 'no' },
        { text: i18n.tc('Yes'), value: 'yes' },
      ],
    },
  }),
  inject: ['$validator'],
  mixins: [
    ValidateState,
  ],
  components: {
    ArrowIcon,
  },
  model: {
    prop: 'complaintDescriptionData',
  },
  props: {
    complaintDescriptionData: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.complaintDescriptionData, [key]: value });

      if (key === 'adr' && value === 'yes') {
        this.$store.commit('updatemail', 'Bene.Complaints@fresenius-kabi.com,bene.vig@fresenius-kabi.com');
      } else if (key === 'adr' && value === 'no') {
        this.$store.commit('updatemail', 'Bene.Complaints@fresenius-kabi.com');
      }
    },
  },
  computed: {
    showRemarks() {
      switch (this.complaintDescriptionData.timingOfComplaintObservation) {
        case 'during-usage':
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">

</style>
